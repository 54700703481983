<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div>
      <div class="row mt-5 justify-content-between">
        <div class="col-3" v-if="!readOnly">
          <div>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_add_affiliazione_tab_ente"
              class="btn btn-light text-gray-700 fs-6 text-start"
              @click="avviaCheck = !avviaCheck"
            >
              <!-- <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_add_affiliazione"
              class="btn btn-light text-gray-700 fs-6 text-start"
              @click="checkAff"
            > -->
              <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi aggregazione
            </button>
          </div>
        </div>
      </div>
      <br />
      <div
        class="table-responsive card shadow"
        v-if="loadedAffiliazioni && listaAffiliazioni.length"
      >
        <table class="table table-striped gy-3 gs-7 align-middle">
          <thead>
            <tr
              class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th scope="col">Stagione</th>
              <th scope="col">Codice aggregazione</th>
              <th scope="col">Tipo aggregazione</th>
              <th scope="col">Discipline</th>
              <th scope="col" class="text-center">Data aggregazione</th>
              <th scope="col">Stato</th>
              <th scope="col">Defibrillatore</th>
              <th scope="col" class="w-5"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in listaAffiliazioni" :key="i">
              <td>{{ item.anno }}</td>
              <td>{{ item.cod_affiliazione }}</td>
              <td>{{ item.tipo_affiliazione }}</td>
              <td>
                <span v-for="(disc, i) in item.discipline" :key="i">
                  {{ disc.disciplina }}
                  <span v-show="i < item.discipline.length - 1"> - </span>
                </span>
              </td>
              <td class="text-center">{{ item.data_inizio }}</td>
              <td>
                <h1
                  class="badge"
                  :class="
                    item.stato === 'affiliazione completata'
                      ? 'badge-light-success'
                      : item.stato === 'richiesta validata'
                      ? 'badge-light-warning'
                      : item.stato === 'in approvazione'
                      ? 'badge-secondary'
                      : item.stato === 'da pagare'
                      ? 'badge-light-primary'
                      : item.stato === 'annullata'
                      ? 'badge-light-danger'
                      : 'badge-light'
                  "
                >
                  {{ item.stato }}
                </h1>
              </td>
              <td>
                <i
                  v-show="item.defibrillatore"
                  class="bi bi-check-lg text-success"
                ></i>
                <i
                  v-show="!item.defibrillatore"
                  class="bi bi-x-lg text-danger"
                ></i>
              </td>
              <td class="w-5">
                <div v-if="!readOnly" class="btn-group">
                  <button
                    class="btn btn-sm dropdown"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <a
                      href="#"
                      @click="apprMovAffiliazione(item.id_movimento)"
                      v-if="
                        !item.data_approvazione &&
                        item.approvabile &&
                        !readOnly &&
                        isEnabled('fnSocietaAffiliazioniApprova')
                      "
                    >
                      <li role="presentation" class="navi-item">
                        <div class="b-dropdown-text text-start py-2 px-4">
                          <div class="navi-link">
                            <i class="bi bi-check text-dark fs-3"></i>
                            <span class="text-dark ps-1 fs-7"> Approva </span>
                          </div>
                        </div>
                      </li></a
                    >
                    <a
                      href="#"
                      @click="ratificaAffiliazione()"
                      v-if="
                        !readOnly &&
                        item.ratificabile &&
                        isEnabled('fnSocietaAffiliazioniRatifica')
                      "
                    >
                      <li role="presentation" class="navi-item">
                        <div class="b-dropdown-text text-start py-2 px-4">
                          <div class="navi-link">
                            <i class="bi bi-check text-dark fs-3"></i>
                            <span class="text-dark ps-1 fs-7"> Ratifica </span>
                          </div>
                        </div>
                      </li></a
                    >
                    <a
                      href="#"
                      @click="setIdAffiliazione(item.id_affiliazione)"
                      data-bs-toggle="modal"
                      data-bs-target="#modal_edit-detail-affiliazione_ente"
                      v-if="!readOnly"
                    >
                      <li role="presentation" class="navi-item">
                        <div class="b-dropdown-text text-start py-2 px-4">
                          <div class="navi-link">
                            <i class="bi bi-pen text-dark fs-5"></i>
                            <span class="text-dark ps-1 fs-7" type="button">
                              Modifica
                            </span>
                          </div>
                        </div>
                      </li></a
                    >
                    <a
                      href="#"
                      @click="
                        cancellaRichiesta(item.id_affiliazione, item.stato)
                      "
                      v-if="!readOnly"
                    >
                      <li role="presentation" class="navi-item">
                        <div class="b-dropdown-text text-start py-2 px-4">
                          <div class="navi-link">
                            <i class="bi bi-trash text-dark fs-5"></i>
                            <span class="text-dark ps-1 fs-7">
                              Rimuovi richiesta
                            </span>
                          </div>
                        </div>
                      </li></a
                    >
                    <a
                      href="#"
                      v-if="!readOnly"
                      @click="stampaModuloAffiliazione()"
                    >
                      <li role="presentation" class="navi-item">
                        <div class="b-dropdown-text text-start py-2 px-4">
                          <div class="navi-link">
                            <i class="bi bi-printer text-dark fs-5"></i>
                            <span class="text-dark ps-1 fs-7">
                              Stampa modulo aggregazione
                            </span>
                          </div>
                        </div>
                      </li></a
                    >
                    <a
                      href="#"
                      @click="stampaAttestatoAffiliazione()"
                      v-if="
                        !readOnly && item.stato == 'affiliazione ratificata'
                      "
                    >
                      <li role="presentation" class="navi-item">
                        <div class="b-dropdown-text text-start py-2 px-4">
                          <div class="navi-link">
                            <i class="bi bi-printer text-dark fs-5"></i>
                            <span class="text-dark ps-1 fs-7">
                              Stampa attestato di aggregazione
                            </span>
                          </div>
                        </div>
                      </li></a
                    >
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-else-if="loadedAffiliazioni && !listaAffiliazioni.length"
        class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
      >
        <span class="text-gray-700 ps-3 fs-2">
          Attenzione! Nessuna aggregazione.
        </span>
      </div>
      <div v-else>
        <div class="mx-auto my-auto text-center">
          <div class="spinner-border text-gray-600" role="status"></div>
          <span class="text-gray-600 ps-3 fs-2">Loading</span>
        </div>
      </div>
    </div>
    <ModalAddAffiliazione
      :ente="ente"
      @loadAffiliazioniEnte="refreshAffiliazione"
      :avviaCheck="avviaCheck"
    />
    <EditDetailAffiliazione
      :ente="ente"
      :idAffiliazione="selectedIdAffiliazione"
      @loadAffiliazioniEnte="refreshAffiliazione"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import "vue3-date-time-picker/dist/main.css";
import EditDetailAffiliazione from "@/components/components-fit/enti-aggregati/affiliazioni/EditDetailAffiliazione.vue";
import ModalAddAffiliazione from "@/components/components-fit/enti-aggregati/affiliazioni/ModalAddAffiliazioneTab.vue";
//import ModalAddAffiliazione from "@/components/components-fit/società/affiliazioni/ModalAddAffiliazione.vue";
import {
  //checkAffiliazione,
  deleteAffiliazione,
} from "@/requests/checkAffiliazione";
import {
  pdfModuloAggregazione,
  pdfAttestatoAffiliazione,
} from "@/requests/downloadFiles";

import services from "../../../axios/dbManag";
import { alertFailed, alertSuccess } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import isEnabled from "@/composables/isEnabled.js";

import {
  approvaMovAffiliazione,
  ratificaMovAffiliazione,
} from "@/requests/economatoAffiliazioniRequests";
import { checkAffiliazione } from "@/requests/checkAffiliazione";

export default defineComponent({
  name: "aggregazione-ente",
  components: {
    EditDetailAffiliazione,
    ModalAddAffiliazione,
    Loading,
  },
  props: ["ente", "campiLocked"],
  setup(props) {
    const loadedAffiliazioni = ref(false);
    const listaAffiliazioni = ref([]);
    const store = useStore();

    const stagioneSelected = computed(() => store.getters.stagioneSelected);

    const selectedIdAffiliazione = ref(null);
    const setIdAffiliazione = (id) => {
      selectedIdAffiliazione.value = id;
    };

    const loadAffiliazioniEnte = () => {
      loadedAffiliazioni.value = false;
      services.dbManag
        .post(`/affiliazioni/enti-aggregati/list`, {
          anno: stagioneSelected.value,
          id_societa: props.ente.id,
        })
        .then((res) => {
          listaAffiliazioni.value.splice(0, listaAffiliazioni.value.length);
          res.data.results.forEach((element) => {
            listaAffiliazioni.value.push(element);
          });
          loadedAffiliazioni.value = true;
        })
        .catch((e) => {
          console.log("ERRORE: " + e);
        });
    };

    loadAffiliazioniEnte();

    const refreshAffiliazione = () => {
      loadedAffiliazioni.value = false;
      services.dbManag
        .post(`/affiliazioni/enti-aggregati/list`, {
          anno: stagioneSelected.value,
          id_societa: props.ente.id,
        })
        .then((res) => {
          loadedAffiliazioni.value = true;
          listaAffiliazioni.value.splice(0, listaAffiliazioni.value.length);
          res.data.results.forEach((element) => {
            listaAffiliazioni.value.push(element);
          });
        })
        .catch((e) => {
          console.log("ERRORE: " + e);
        });
    };

    watch(stagioneSelected, () => {
      loadAffiliazioniEnte();
    });

    const isLoading = ref(false);

    const cancellaRichiesta = (id, stato) => {
      if (
        stato === "richiesta validata" ||
        stato === "affiliazione completata"
      ) {
        alertFailed(
          "L'aggregazione risulta gia' approvata dalla Federazione, pertanto non è possibile rimuovere la richiesta."
        );
        return;
      }
      Swal.fire({
        title: "Sei sicuro di voler eliminare la richiesta?",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Si",
        customClass: {
          cancelButton: "btn fw-bold btn-light-danger",
          confirmButton: "btn fw-bold btn-light-primary",
        },
      }).then((e) => {
        if (e.isDismissed) {
          return;
        } else {
          isLoading.value = true;
          deleteAffiliazione(id).then((res) => {
            isLoading.value = false;
            if (res.status == 200) {
              alertSuccess("Richiesta eliminata con successo");
              refreshAffiliazione();
            } else
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
          });
        }
      });
    };

    const objControlloDownloadDocumentoAffiliazione = ref(null);
    const societaGiaAffiliata = ref(false);
    const stampaModuloAffiliazione = async () => {
      societaGiaAffiliata.value = true;
      isLoading.value = true;
      await checkAffiliazione({
        anno: stagioneSelected.value,
        id_societa: props.ente.id,
      }).then((res) => {
        console.log(res);
        if (
          res.data &&
          res.data.message.includes(
            "L'ente risulta gi&agrave; aggregato o con richiesta di aggregazione per l'anno selezionato."
          )
        ) {
          societaGiaAffiliata.value = true;
        }

        objControlloDownloadDocumentoAffiliazione.value =
          res.objControlloDownloadDocumentoAffiliazione;
      });
      if (
        societaGiaAffiliata.value == true ||
        (societaGiaAffiliata.value == false &&
          !objControlloDownloadDocumentoAffiliazione.value) ||
        (objControlloDownloadDocumentoAffiliazione.value
          .affiliazionePrecedente == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.atto == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.debiti == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.consiglioDirettivo ==
            "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.discipline == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.affiliazione ==
            "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.impiantiCampi ==
            "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.privacy == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.sanzioni == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.pickleball == "OK" &&
          objControlloDownloadDocumentoAffiliazione.value.tutela_minori == "OK")
      ) {
        await pdfModuloAggregazione(props.ente.id, stagioneSelected.value);
        isLoading.value = false;
        return;
      } else {
        alertFailed(
          "Prima di scaricare la domanda di aggregazione è necessario completare la richiesta per l'anno corrente"
        );
        isLoading.value = false;
        return;
      }
    };

    const stampaAttestatoAffiliazione = async () => {
      isLoading.value = true;
      await pdfAttestatoAffiliazione(props.ente.id, stagioneSelected.value);
      isLoading.value = false;
    };

    const showMsgClosed = () => {
      alertFailed(
        "Attenzione! Le aggregazioni non sono ancora state aperte per l'anno selezionato."
      );
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Affiliazioni Ente", [
        "Lista Ente",
        "Dettaglio Ente",
      ]);
    });

    const avviaCheck = ref(false);

    const apprMovAffiliazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'aggregazione e i relativi movimenti verranno approvati.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Approva",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await approvaMovAffiliazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Movimento approvato correttamente");
              loadAffiliazioniEnte();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const ratificaAffiliazione = () => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'aggregazione verrà ratificata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Ratifica",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await ratificaMovAffiliazione(
            props.ente.id,
            stagioneSelected.value
          ).then((res) => {
            if (res.status == 200) {
              alertSuccess("Aggregazione ratificata correttamente");
              loadAffiliazioniEnte();
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    return {
      avviaCheck,

      date: null,
      date2: null,
      loadedAffiliazioni,
      listaAffiliazioni,
      stagioneSelected,
      selectedIdAffiliazione,
      //checkAff,
      deleteAffiliazione,
      cancellaRichiesta,
      setIdAffiliazione,
      refreshAffiliazione,
      isLoading,
      showMsgClosed,

      stampaModuloAffiliazione,
      stampaAttestatoAffiliazione,
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      isEnabled,
      apprMovAffiliazione,
      ratificaAffiliazione,
    };
  },
});
</script>

<style scoped>
.dropdown-menu {
  position: fixed !important;
}

/* .table-responsive {
  min-height: 350px !important;
} */
#swal2-content {
  margin-top: 0;
}
</style>
